.wrapper {
  a {
    text-decoration: none;
  }
}

.change-order {
  background: green;
  color: white;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid white;
  cursor: pointer;
  a {
    text-decoration: none;
  }

  &:hover {
    background: rgb(1, 67, 1);
  }
}

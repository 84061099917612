.form {
  margin: auto;
  width: 300px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  outline: 2px solid grey;
  padding: 10px;
  border-radius: 10px;
  background: #cbcbcb;

  .group {
    input {
      padding: 5px;
      display: block;
      margin: 5px auto;
    }

    span {
      display: flex;
      background: tomato;
      color: white;
      padding: 5px;
      font-size: 14px;
      margin: 5px;
      position: relative;
    }

    button {
      padding: 10px;
      border-radius: 5px;
      border: none;
      outline: 1px solid grey;
      margin-top: 10px;
      cursor: pointer;
    }
  }
}

.logged {
  margin: auto;
  width: 300px;
}

.loading {
  width: 300px;
  position: relative;
  height: 130px;
  margin: auto;
}

.menu {
  a {
    text-decoration: none;
    color: var(--light);
    transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    padding: 5px 2px;
    border-radius: 5px;
    margin: 5px;

    @media (max-width: 800px) {
      width: 1000px !important;
      font-size: 12px;
    }
  }
  ul {
    padding: 0;
    margin: 0;
    li {
      padding: 0;
      text-align: center;
      display: inline-block;
      font-size: 14px;
      margin: 20px;
      display: inline-block;
      @media (max-width: 800px) {
        margin: 2px;
        font-size: 14px;
      }
    }
  }

  @media (max-width: 800px) {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  }
}

/* light theme */
.light a {
  color: var(--dark);
}

.light a:hover {
  box-shadow: inset 200px 0 0 0 var(--dark);
  color: var(--light);
}

.light .active {
  box-shadow: inset 200px 0 0 0 rgb(211, 209, 209);
  border-radius: 5px;
}

/* dark theme */
.dark a {
  color: var(--light);
}

.dark a:hover {
  box-shadow: inset 200px 0 0 0 var(--light);
  color: var(--dark);
}

.dark .active {
  box-shadow: inset 200px 0 0 0 rgb(83, 83, 83);
  border-radius: 5px;
}

/* @import url('../node_modules/react-grid-layout/css/styles.css');
@import url('../node_modules/react-resizable/css/styles.css'); */

/* fonts */
@font-face {
  font-family: 'Marmelad';
  src: local('Marmelad'), url(./assets/fonts/Marmelad-Regular.ttf) format('truetype');
}

/* main colors */
:root {
  --dark: rgb(61, 61, 61);
  --light: rgb(229, 229, 229);
}

body > #root > div {
  min-height: 97vh;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

.app {
  margin: 0px;
  display: flex;
  flex-direction: column;
}

.container {
  width: 80%;
  margin: auto;
  padding: 10px;
  word-break: break-all;
}

.light {
  background: var(--light);
  color: var(--dark);
}

.dark {
  background: var(--dark);
  color: var(--light);
}

.empty-list {
  width: 100%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.7;
  font-size: 18px;
  margin-top: 60px;
}

.dropdown-container {
  color: red !important;
}

.empty {
  text-align: center;
  color: grey;
  font-size: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
}

@media (max-width: 800px) {
  .container {
    width: 100%;
    padding: 0;
  }
}

.success {
  max-width: 600px;
  background: rgb(163, 194, 163);
  text-align: center;
  min-height: 100px;
  margin: 10px auto;
  border-radius: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close {
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 5px;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  background: #ffffff33;
}

.form-group {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  align-items: center;
  margin: 10px;
  font-size: 14px;

  label {
    font-size: 16px;
    font-weight: bold;
  }

  span {
    color: red;
    font-size: 12px;
    text-align: center;
    display: flex;
    justify-content: center;
  }
}

.input {
  width: 70%;
}

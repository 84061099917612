.panel {
  width: 80%;
  background: grey;
  margin: 10px auto;
  border-radius: 10px;
  height: 40px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
  color: white;
}

.logout {
  margin: 10px 0;
  padding-bottom: 10px;
  cursor: pointer;
  &:hover {
    color: yellow;
  }
}

.menu {
  padding-left: 0;
  display: flex;
  list-style: none;
  justify-content: center;
  a {
    text-decoration: none;
  }

  li {
    margin: 0 10px;
    padding: 5px;
    border-radius: 5px;
    color: var(--dark);
    border: 1px solid var(--dark);
  }

  li .dark {
    margin: 0 20px;
    color: var(--light);
    border: 1px solid var(--light);
  }
}

.active {
  background: #c0c0c0;
}

.dark {
  li {
    color: var(--light);
    border: 1px solid var(--light);
  }

  .active {
    background: #c0c0c0;
    color: var(--dark);
  }
}

.footer {
  height: 100%;
  width: 100%;
  margin: auto;
  height: 120px;
  bottom: -27px;
  background: rgb(164, 164, 164);
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--dark);

  .socials {
    display: flex;

    .social {
      margin: 0 10px;
      img {
        width: 40px;
      }
    }
  }

  .developer {
    border-radius: 10px;
    padding: 5px;
    margin-top: 10px;
    a {
      margin-left: 2px;
      color: var(--dark);
    }
  }
}

.dark {
  color: var(--light);
  background: rgb(116, 116, 116);
}

.card {
  border-radius: 12px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 90px;
  font-weight: bold;
  color: transparent;
  margin-top: 10px;
  height: 450px;
  font-family: 'Ubuntu', sans-serif;

  .text {
    margin-right: 20px;
  }

  .image {
    margin-left: 20px;
    position: relative;
    width: 500px;
    height: 500px;
    justify-content: center;
    display: flex;
    align-items: center;

    img {
      position: relative;
      z-index: 2;
      width: 300px;
      height: 320px;
      object-fit: contain;
    }

    .bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }
}

@media (max-width: 800px) {
  .card {
    height: 180px;
    display: flex;
    justify-content: center;
    width: 100%;
    border-radius: 0%;
    text-transform: uppercase;
  }
  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    left: 20px;
  }

  .image {
    width: 160px !important;
    height: 160px !important;
    justify-content: center !important;
    display: flex !important;

    img {
      width: 100px !important;
      height: 120px !important;
      display: flex !important;
    }
  }
}

@media (max-width: 1200px) {
  .image {
    width: 400px !important;
    height: 400px !important;

    img {
      width: 300px !important;
      height: 300px !important;
      display: flex !important;
    }
  }
}

@media (max-width: 980px) {
  .image {
    width: 300px !important;
    height: 300px !important;

    img {
      width: 200px !important;
      height: 200px !important;
      display: flex !important;
    }
  }
}

@media (max-width: 500px) {
  .text {
    left: 20px;
    text-align: center;
  }
  .image {
    width: 190px !important;
    height: 190px !important;

    img {
      width: 130px !important;
      height: 130px !important;
      display: flex !important;
    }
  }
}

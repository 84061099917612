.form {
  position: relative;
  z-index: 7;
  color: var(--dark);
  padding: 10px;
  background: var(--light);
  width: 600px;
  margin: auto;
  outline: 1px solid grey;
  border-radius: 10px;

  .form-group {
    display: flex;
    justify-content: space-between;
    background: rgb(203, 203, 203);
    padding: 10px;
    border-radius: 5px;
    align-items: center;

    .inputWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 40%;

      span {
        display: block;
        font-size: 14px;
        background: tomato;
        padding: 2px 5px;
        color: white;
        margin-top: 5px;
        border-radius: 5px;
      }
    }

    input {
      width: 100%;
      background: white;
      padding: 5px;
      font-size: 16px;
      border-bottom: none;
      border-radius: 5px;
    }
  }

  img {
    width: 300px;
    height: 300px;
    object-fit: cover;
    margin: 20px auto;
    box-shadow: 2px 2px 15px black;
    display: block;
    outline: 10px solid white;
  }

  .form-group {
    margin: 10px;
  }

  input {
    border: none;
    border-bottom: 1px solid grey;
    background: none;

    &:focus {
      outline: none;
    }
  }
}

.bottom {
  margin-top: 20px;
  text-align: center;
  padding: 10px;
}

.btn-save {
  margin-right: 10px;
}

.catBtn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.categories {
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: flex-start;

  .dark {
    a {
      text-decoration: none;
      color: var(--light);
    }
  }

  li {
    display: inline-block;
    margin: 0 5px;
    a {
      text-decoration: none;
      color: var(--dark);
    }

    @media (max-width: 800px) {
      margin-bottom: 10px;
    }

    span {
      opacity: 0.6;
      margin-left: 5px;
      border-radius: 100%;
      background: #828282;
      color: var(--light);
      display: flex;
      font-size: 12px;
      width: 20px;
      justify-content: center;
      align-items: center;
      height: 20px;
    }
  }

  @media (max-width: 800px) {
    display: block;
  }
}

.form {
  background: white;
  display: flex;
  flex-direction: column;
  z-index: 7;
  border-radius: 10px;
  color: var(--dark);

  input {
    padding: 5px;
  }
}

.active-dark {
  background: var(--light);
  color: var(--dark);
}

.active-light {
  background: var(--dark);
  color: var(--light);
}

.cat-edit-btn {
  background: rgb(111, 124, 111);
  color: white;
  justify-content: center;
  display: flex;
  align-items: center;

  img {
    width: 20px;
    object-fit: contain;
  }
}

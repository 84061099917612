.title {
  font-size: 34px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  width: 60%;
  padding-top: 40px;
  margin: auto;
}

@media (max-width: 1200px) {
  .wrapper {
    width: 100%;
    padding-top: 40px;
    margin: auto;
  }
}

@media (max-width: 800px) {
  .wrapper {
    width: 100%;
    padding-top: 40px;
    margin: auto;
  }

  .title {
    font-size: 24px;
  }
}

.bottom {
  margin-top: 20px;
  text-align: center;
  padding: 10px;
}

.btn-save {
  border: 1px solid grey;
  width: 90px;
  text-align: center;
  border-radius: 10px;
  padding: 10px;
  display: inline-block;
  margin-right: 10px;
  transition: 0.3s;
  cursor: pointer;
  color: black;
  &:hover {
    background: rgb(167, 167, 167);
  }
}

.modal {
  min-height: 60px;
  min-width: 180px;
  color: var(--dark);

  input {
    border: 1px solid grey;
  }
}

.btn-cancel {
  border: 1px solid grey;
  width: 80px;
  color: var(--light);
  background: var(--dark);
  text-align: center;
  border-radius: 10px;
  padding: 10px;
  display: inline-block;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    background: rgba(0, 0, 0, 0.297);
  }
}

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
}

.bg {
  background: #0000009d;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 2;
}

.modal-wrapper {
  background: white;
  z-index: 7;
  padding: 10px;
  border-radius: 10px;
  transition: 1s;
}

.modalOpen {
  opacity: 1;
  transition: 1s;
  transform: translateY(0);
}

.modalClosed {
  opacity: 0;
  transition: 1s;
  transform: translateY(-1000%);
}

.contacts {
  .block {
    margin-top: 20px;
    .label {
      text-align: center;
      font-weight: bold;
      font-size: 24px;
    }

    .info {
      text-align: center;
      font-size: 24px;

      a {
        text-align: center;
        text-decoration: none;
        color: black;
      }
    }
  }

  .img {
    display: flex;
    justify-content: center;

    img {
      height: 350px;
      width: 600px;
      border-radius: 10px;
      object-fit: cover;

      @media (max-width: 980px) {
        width: 100%;
        border-radius: 0;
        height: 350px;
      }
    }
  }
}

.map-wrapper {
  width: 80%;
  margin: auto;
  margin-top: 20px;

  @media (max-width: 980px) {
    width: 100%;
  }
}

.map {
  border: none;
  width: 100%;
  margin: auto;
  display: block;
}

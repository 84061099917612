.left {
  justify-content: left;
}
.right {
  justify-content: right;
}

.left-bottom {
  justify-content: left;
  position: relative;
  left: -20px;
}

.advantage {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  transition: 0.3s;
  cursor: pointer;
  font-size: 18px;

  &:hover {
    transform: scale(1.1);
  }

  .info {
    width: 80%;

    .title {
      margin: 0 20px 15px 20px;
      font-size: 22px;
      font-weight: bold;
    }

    .text {
      margin: 0 20px;
      word-break: normal;

      textarea {
        resize: none;
      }
    }
  }

  .img {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 130px;
    width: 130px;
    align-items: center;
    border-radius: 100%;
    margin: auto;

    .img-bg {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 130px;
      width: 130px;
      align-items: center;
      background: var(--dark);
      border: 1px solid var(--light);
      border-radius: 100%;
      margin: auto;
      z-index: 0 !important;
    }

    .img-wrapper {
      position: relative;
      z-index: 2;
    }

    img {
      object-fit: cover;
      width: 75px;
      position: relative;
    }

    .edit-image {
      position: relative;
      z-index: 10;
      background: white;
      padding: 10px;
      margin-top: 50px;
      border-radius: 10px;
      width: 180px;
    }

    .bg {
      position: absolute;
      left: 0px;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }
}

.light {
  border: 1px solid var(--dark) !important;
}

.dark {
  border: 1px solid var(--light) !important;
}

.bottom {
  display: flex;
  margin-left: 20px;
  margin-top: 10px;

  .btn {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: white;
    cursor: pointer;

    img {
      width: 20px;
    }
  }

  .del {
    background: tomato;

    &:hover {
      background: rgb(141, 55, 39);
    }
  }

  .edit {
    background: green;
    margin-left: 5px;
    margin-right: 5px;

    &:hover {
      background: rgb(0, 60, 0);
    }
  }

  .ok {
    background: green;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 100%;

    &:hover {
      background: rgb(0, 60, 0);
    }
  }

  .cancel {
    background: tomato;
    border-radius: 100%;

    &:hover {
      background: rgb(141, 55, 39);
    }
  }
}

@media (max-width: 800px) {
  .advantage {
    width: 90%;
    margin: 20px auto;
    padding: 10px;
    font-size: 14px;

    &:hover {
      transform: scale(1);
    }

    .info {
      .title {
        font-size: 16px;
      }
    }

    .img-bg {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 88px !important;
      width: 90px !important;
      align-items: center;
      background: #000;
      border-radius: 100%;
      position: absolute;
    }

    .img {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 88px !important;
      width: 90px !important;
      align-items: center;
      border-radius: 100%;

      img {
        width: 40px;
        object-fit: cover;
        position: relative;
        z-index: 0;
      }
    }
  }
}

.inputImgWrapper {
  overflow: hidden;
  width: 180px;
  border-radius: 10px;
}

.inputWrapper {
  flex-direction: column;
  display: flex;

  input {
    width: 40%;
  }

  span {
    display: inline-block;
    font-size: 14px;
    background: tomato;
    color: white;
    font-weight: 300;
    width: 40%;
    text-align: center;
    margin: 3px 0;
  }
}

.imgWrapper {
  width: 25%;
}

.deleteModalText {
  margin: 20px;
  font-size: 18px;
}

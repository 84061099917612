.form {
  border-radius: 10px;

  position: relative;
  z-index: 7;
  color: var(--dark);
  padding: 25px 10px 10px 10px;
  background: var(--light);
  min-width: 300px;

  input {
    background: white;
    padding: 10px;
    border-radius: 5px;
  }

  button {
    margin: 10px 0;
    border: 1px solid green;
    padding: 10px;
    border-radius: 5px;
    background: green;
    color: white;
    font-weight: bold;
    font-size: 14px;
    transition: 0.4s;
    cursor: pointer;

    &:hover {
      background: rgb(0, 52, 0);
    }
  }
}

.photos {
  position: relative;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  min-height: 300px;

  .photo {
    outline: 1px solid rgb(128, 128, 128);
    position: relative;
    margin: 5px;
    width: 280px;
    border-radius: 10px;
    padding: 5px;
  }

  img {
    width: 100%;
    height: 280px;
    object-fit: cover;
    border-radius: 10px;
  }
}

.dragging {
  background: rgb(164, 219, 148);
}

.del {
  position: absolute;
  background: tomato;
  width: 40px;
  border-radius: 5px;
  height: 40px;
  top: 10px;
  right: 10px;
  z-index: 4;
  cursor: pointer;
  transition: 0.4s;

  .del-img {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 25px !important;
      height: 30px;
      padding: 5px;
      margin: 0;
      object-fit: cover;
    }
  }

  &:hover {
    background: rgb(145, 1, 1);
  }
}

.filesInput {
  margin-bottom: 20px;
  input {
    display: none;
  }

  label {
    background: #848484;
    border: 1px solid black;
    color: white;
    padding: 10px;
    border-radius: 10px;
  }
}

.chosenFiles {
  display: inline-block;
  margin-left: 10px;
  text-decoration: underline;
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 10;
  align-items: center;
  border-radius: 20px;
}

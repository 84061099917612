.logo {
  margin: 5px;
  width: 80px;
  height: 65px;
  img {
    margin: 5px;
    width: 70px;
    object-fit: cover;
  }
}

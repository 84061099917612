.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 20;

  img {
    display: flex;
    width: 20px;
    object-fit: cover;
  }
}

.header {
  display: flex;
  align-items: center;
  width: 80%;
  margin: auto;

  @media (max-width: 800px) {
    width: 100%;
  }
}

.arrow {
  left: 0;
  top: -3px;
  width: 35px;
  border: 1px solid rgb(0, 0, 0);
  height: 35px;
  cursor: pointer;
  transition: 0.2s;
  background: #eeeeeea8;
  border-radius: 50%;
  position: absolute;
  &:hover {
    background: #aaaaaa;
  }
}

.icon {
  fill: var(--text-color);
}

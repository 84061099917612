.form {
  margin: 10px;
  label {
    color: var(--dark);
  }
  input {
    padding: 5px;
    margin-left: 10px;
  }
}

.title {
  text-align: center;
}

.wrapper {
  h2 {
    text-align: center;
  }

  ul {
    padding: 0;

    li {
      cursor: pointer;
    }
  }

  .category {
    display: flex;
    align-items: center;
    margin: 5px;
    outline: 1px solid grey;
    padding: 10px;
    border-radius: 5px;

    .name {
      font-size: 18px;
      font-weight: bold;
    }

    .btn {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      color: white;
      cursor: pointer;

      img {
        width: 20px;
      }
    }

    .del {
      background: tomato;

      &:hover {
        background: rgb(141, 55, 39);
      }
    }

    .edit {
      background: green;
      margin-left: 5px;
      margin-right: 5px;

      &:hover {
        background: rgb(0, 60, 0);
      }
    }

    .input {
      display: flex;
      border: 1px solid grey;
      padding: 5px;
      background: rgba(147, 248, 147, 0.511);

      input {
        padding: 2px;
        font-size: 18px;
      }
    }

    .buttons {
      display: flex;
    }

    .ok {
      background: green;
      margin-left: 5px;
      margin-right: 5px;
      border-radius: 100%;

      &:hover {
        background: rgb(0, 60, 0);
      }
    }

    .cancel {
      background: tomato;
      border-radius: 100%;

      &:hover {
        background: rgb(141, 55, 39);
      }
    }
  }
}

.add-button {
  margin: auto;
  display: flex;
  justify-content: center;
}

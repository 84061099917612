.list {
  outline: 2px solid white;
  border-radius: 10px;
  padding: 10px;

  .album {
    width: 180px;
    height: 200px;
    cursor: pointer;

    img {
      border-radius: 10px;
      width: 200px;
      height: 200px;
      object-fit: cover;
    }
  }

  .btnSave {
    text-align: center;
    padding-bottom: 10px;
    border-bottom: 1px solid white;
    border-top: 1px solid white;
    padding-top: 10px;
    margin-bottom: 10px;

    .btn {
      margin-right: 5px;
    }
  }
}

.title {
  text-align: center;
  font-size: 18px;
  margin-bottom: 10px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.add-btn {
  background: green;
  display: block;
  color: white;
  text-align: center;
  padding: 10px;
  margin-right: 5px;
  border-radius: 10px;
  border: 1px solid white;
  cursor: pointer;

  &:hover {
    background: rgb(1, 67, 1);
  }
}

h3 {
  text-align: center;
}

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
}

.bg {
  background: #0000009b;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 6;
}

.form {
  position: relative;
  z-index: 7;
  color: var(--dark);
  padding: 10px;
  background: var(--light);
  width: 450px;
}

.savingWrapper {
  min-height: 100px;
}

.buttons {
  display: flex;
  justify-content: center !important;
  flex-direction: row;
}

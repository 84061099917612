.list {
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 800px) {
    display: block;
  }
}

.editOrderAlbums {
  outline: 10px solid grey;
}

.test {
  width: 300px;
  height: 300px;
  outline: 2px solid red;
}

.filesInput {
  margin-bottom: 20px;
  input {
    display: none;
  }

  label {
    cursor: pointer;
    background: #848484;
    border: 1px solid black;
    color: white;
    padding: 10px;
    border-radius: 10px;
  }
}

.chosenFiles {
  display: inline-block;
  margin-left: 10px;
  text-decoration: underline;
}

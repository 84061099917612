.each-slide {
  height: 1200px;
  outline: 2px solid red !important;
}

.album {
  position: relative;
}

.rsis-image {
  background-position: center !important;
  background-size: cover !important;
  position: relative !important;
  top: 100% !important;
  right: 100% !important;
  border: 1px solid red !important;
}
.title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  position: relative;

  .admin-buttons {
    position: static;
    justify-content: center;
    margin-top: 15px;
  }
}

.video {
  width: 80%;
  margin: 20px auto;
  height: 550px;
  border-radius: 15px;
  overflow: hidden;
}

.options {
  position: relative;
  width: 80%;
  min-height: 100px;
  margin: 20px auto;
  border-radius: 15px;
  border: 1px solid var(--light);
  display: flex;
  flex-direction: column;
  padding: 10px 0;

  span {
    margin-left: 10px;
    margin-top: 20px;
    font-size: 22px;
    text-decoration: underline;
    text-align: center;
    margin-bottom: 10px;
  }
}

.slider {
  display: flex;
  height: 1020px;
  width: 80%;
  margin: auto;
  border-radius: 10px;
  overflow: hidden;
  justify-content: center;

  @media (max-width: 1920px) {
    width: 80%;
    border-radius: 0;
    height: 720px;
  }

  @media (max-width: 800px) {
    width: 100%;
    border-radius: 0;
    height: 450px;
  }

  @media (max-width: 1250px) {
    width: 80%;
    border-radius: 0;
    height: 550px;
  }

  @media (max-width: 970px) {
    width: 100%;
    border-radius: 0;
    height: 550px;
  }

  @media (max-width: 650px) {
    width: 100%;
    border-radius: 0;
    height: 370px;
  }
}

.options {
  border: 1px solid grey;
  word-break: normal;

  .label {
    font-size: 20px;
    font-weight: bold;
    display: inline-block;
    margin-right: 5px;

    @media (max-width: 800px) {
      font-size: 18px;
    }
  }

  @media (max-width: 800px) {
    width: 90%;
  }
}

.info {
  margin: 10px;
  display: inline-block;
  font-size: 22px;
}

.phones {
  text-align: center;
  font-size: 18px;
  word-break: normal;
}

.contacts {
  justify-content: center;
}

.admin-buttons {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  z-index: 2;

  .del {
    background: tomato;
    margin-left: 5px;
    padding: 5px;
    cursor: pointer;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    transition: 0.3s;
    &:hover {
      background: rgb(158, 60, 43);
    }

    img {
      height: 30px;
      object-fit: contain;
    }
  }

  .edit {
    background: green;
    padding: 5px;
    cursor: pointer;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    &:hover {
      background: rgb(0, 89, 0);
    }

    img {
      width: 30px;
      object-fit: contain;
    }
  }
}

.add-btn {
  background: green;
  display: block;
  color: white;
  width: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid white;
  cursor: pointer;
  transition: 0.4s;
  font-weight: normal;
  margin-left: 10px;
  position: relative;
  top: -20px;
  margin: auto;

  &:hover {
    background: rgb(0, 71, 0);
  }
}

.form {
  color: black;
  label {
    margin-right: 5px;
  }

  input {
    border: 1px solid grey;
  }
  textarea {
    resize: none;
  }

  .form-group {
    justify-content: space-between;
    display: flex;
    margin: 20px;
    align-items: center;
  }
}

.test {
  width: 300px;
  height: 300px;
  background: #000;
}

.album {
  width: 285px;
  margin: 8px;
  margin-bottom: 20px;
  position: relative;

  .details {
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    word-break: normal;

    .linkDetails {
      padding: 5px;
      min-width: 90px;
    }

    a {
      color: var(--light);
    }
  }

  .title {
    margin: 5px 0;
    font-size: 16px;
    cursor: default;
    font-size: 22px;
  }

  .cover {
    border-radius: 12px;
    height: 285px;
    overflow: hidden;
    transition: 0.5s;
    img {
      object-fit: cover;
      width: 285px;
      height: 285px;
      transition: 0.5s !important;
      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .light a {
    color: var(--dark);
  }

  @media (max-width: 800px) {
    display: block;
    margin: 20px auto;
  }
}

.admin-buttons {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  z-index: 2;

  .del {
    background: tomato;
    margin-left: 5px;
    padding: 5px;
    cursor: pointer;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    transition: 0.3s;
    &:hover {
      background: rgb(158, 60, 43);
    }

    img {
      height: 30px;
      object-fit: contain;
    }
  }

  .edit {
    background: green;
    padding: 5px;
    cursor: pointer;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    &:hover {
      background: rgb(0, 89, 0);
    }

    img {
      width: 30px;
      object-fit: contain;
    }
  }
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.deleteModalText {
  margin: 20px;
  font-size: 18px;
}

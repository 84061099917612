.form-group {
  margin: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  label {
    font-weight: bold;
  }
}

input {
  border: none;
  font-size: 12px;
  background: white;
  border-radius: 5px;
  padding: 8px;

  &:focus {
    outline: none;
  }
}

.input {
  display: flex;
  flex-direction: column;
  position: relative;
  span {
    color: red;
    font-size: 13px;
    text-align: center;
    margin-top: 5px;
  }
}

.button {
  border: 1px solid var(--dark);
  border-radius: 7px;
  padding: 5px 10px;
  display: inline-block;
  transition: 0.2s;
  &:hover {
    cursor: pointer;
    background: var(--dark);
    color: var(--light);
  }
}

.dark {
  border: 1px solid var(--light);
  &:hover {
    cursor: pointer;
    background: var(--light);
    color: var(--dark);
  }
}
